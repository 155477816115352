import React, { useEffect, useState } from 'react';
import loadable from '@loadable/component';
import { bool, object, array } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { propTypes } from '../../util/types';
import FallbackPage from './FallbackPage';
import { ExternalLink, H1, H2, H3, NamedLink, ResponsiveImage } from '../../components';
import { spxHero1URL, spxHero2URL, spxHero3URL } from '../../config/configBranding';
import OtherListings from '../ListingPage/OtherListings/OtherListings';

import css from './LandingPage.module.css';
import { fetchListings, fetchFavoriteListings, fetchPopularListings } from './LandingPage.duck';
import { useConfiguration } from '../../context/configurationContext';
import { FormattedMessage, useIntl } from 'react-intl';

const PageBuilder = loadable(() =>
  import(/* webpackChunkName: "PageBuilder" */ '../PageBuilder/PageBuilder')
);

export const LandingPageComponent = props => {
  const {
    pageAssetsData,
    inProgress,
    error,
    fetchListings,
    fetchFavoriteListings,
    fetchPopularListings,
    listings,
    favoriteListings,
    popularListings,
  } = props;

  const heroSections = [
    {
      header: 'LandingPage.heroHeader',
      subheader: 'LandingPage.heroSubheader',
      paragraph1: 'LandingPage.heroParagraph1',
      paragraph2: 'LandingPage.heroParagraph2',
      imageUrl: spxHero1URL,
    },
    {
      header: 'LandingPage.electionsHeader',
      subheader: 'LandingPage.electionsSubheader',
      paragraph1: 'LandingPage.electionsParagraph1',
      paragraph2: 'LandingPage.electionsParagraph2',
      imageUrl: spxHero2URL,
    },
    {
      header: 'LandingPage.specificTitle',
      subheader: 'LandingPage.specificSubtitle',
      paragraph1: 'LandingPage.specificParagraph1',
      paragraph2: 'LandingPage.specificParagraph2',
      imageUrl: spxHero3URL,
    },
  ];

  const config = useConfiguration();
  const intl = useIntl();

  useEffect(() => {
    fetchListings(config);
    fetchFavoriteListings(config);
    fetchPopularListings(config);
  }, [fetchListings, fetchFavoriteListings, fetchPopularListings, config]);

  const [activeIndex, setActiveIndex] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);

  const selectHero = index => {
    if (index !== activeIndex && !isTransitioning) {
      setIsTransitioning(true);

      setTimeout(() => {
        setActiveIndex(index);
      }, 500);

      setTimeout(() => {
        setIsTransitioning(false);
      }, 1000);
    }
  };

  const { header, subheader, paragraph1, paragraph2, imageUrl } = heroSections[activeIndex];

  const showMoreLink = (
    <NamedLink name="SearchPage" className={css.showMoreLink} target="_blank">
      <FormattedMessage id="OtherListings.showMore" />
    </NamedLink>
  );

  return (
    <PageBuilder
      inProgress={inProgress}
      error={error}
      fallbackPage={<FallbackPage error={error} />}
    >
      <div className={css.landingRoot}>
        <div className={css.hero}>
          <div className={`${css.heroContent} ${isTransitioning ? css.fade : ''}`}>
            <div className={css.heroText}>
              {header && (
                <H1>
                  <FormattedMessage id={header} />
                </H1>
              )}
              {subheader && (
                <H2>
                  <FormattedMessage id={subheader} />
                </H2>
              )}
              {paragraph1 && (
                <p>
                  <FormattedMessage id={paragraph1} />
                </p>
              )}
              {paragraph2 && (
                <p>
                  <FormattedMessage id={paragraph2} />
                </p>
              )}

              <NamedLink name="SearchPage" className={css.heroNamedLink} target="_blank">
                <FormattedMessage id="LandingPage.browseCategories" />
              </NamedLink>
            </div>
            <div className={css.heroImage}>
              <ResponsiveImage
                rootClassName={css.rootForHeroImage}
                alt={intl.formatMessage({ id: 'LandingPage.heroAlt' })}
                image={{
                  id: 'spx-hero',
                  type: 'imageAsset',
                  attributes: {
                    variants: {
                      scaled: {
                        height: 400,
                        width: 600,
                        url: imageUrl,
                      },
                    },
                  },
                }}
                variants={['scaled']}
              />
            </div>
          </div>
        </div>

        {/* <div className={css.dotNavigation}>
          {heroSections.map((_, index) => (
            <button
              key={index}
              className={index === activeIndex ? css.activeDot : css.dot}
              onClick={() => selectHero(index)}
            />
          ))}
        </div> */}

        <div className={css.listings}>
          <H3>
            <FormattedMessage id="LandingPage.staffFavs" />
          </H3>
          <OtherListings listings={favoriteListings} showMoreLink={showMoreLink} />
        </div>

        <div className={css.elections}>
          <div className={css.electionsText}>
            <H3>
              <FormattedMessage id="LandingPage.cloudTitle" />
            </H3>
            <p>
              <FormattedMessage id="LandingPage.cloudParagraph" />
            </p>
            <ExternalLink className={css.electionsNamedLink} href="https://spxcloud.app/">
              <FormattedMessage id="LandingPage.learnMore" />
            </ExternalLink>
          </div>
        </div>

        <div className={css.listings}>
          <H3>
            <FormattedMessage id="LandingPage.topElectionProducts" />
          </H3>
          <OtherListings listings={popularListings} showMoreLink={showMoreLink} />
        </div>

        <div className={css.specific}>
          <div className={css.specificText}>
            <H3>
              <FormattedMessage id="LandingPage.specificTitle" />
            </H3>
            <p>
              <FormattedMessage id="LandingPage.specificParagraph1" />
            </p>
            <NamedLink name="ProductIdeaPage" className={css.specificNamedLink}>
              <FormattedMessage id="LandingPage.submitAProductIdea" />
            </NamedLink>
          </div>
        </div>

        <div className={css.listings}>
          <H3>
            <FormattedMessage id="LandingPage.latestReleases" />
          </H3>
          <OtherListings listings={listings} showMoreLink={showMoreLink} />
        </div>
      </div>
    </PageBuilder>
  );
};

LandingPageComponent.propTypes = {
  pageAssetsData: object,
  inProgress: bool,
  error: propTypes.error,
  listings: array,
};

const mapStateToProps = state => {
  const { pageAssetsData, inProgress, error } = state.hostedAssets || {};
  const { listings, favoriteListings, popularListings } = state.LandingPage || {};
  return { pageAssetsData, inProgress, error, listings, favoriteListings, popularListings };
};

const mapDispatchToProps = dispatch => ({
  fetchListings: config => dispatch(fetchListings(config)),
  fetchFavoriteListings: config => dispatch(fetchFavoriteListings(config)),
  fetchPopularListings: config => dispatch(fetchPopularListings(config)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPage = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(LandingPageComponent);

export default LandingPage;
